<template>
  <div class="m1">
    <div class="hd">
      <div class="h1">
        公司列表
      </div>
     <!-- <div class="h2">
        添加员工
      </div> -->
    </div>

    <!-- 列表 -->
    <div class="lb">

      <div class="hd bg">
        <!-- 账号密码 -->
        <div>
          <span class="hd_1">
            序号
          </span>
          <span class="hd_2">
            <div style="margin-left: 1rem;">
              公司名称
            </div>
          </span>
        </div>
      </div>

      <!-- 主体 -->
      <div style="margin-top: 2rem;">
        <div class="main" v-for="(item,index) in Data" :key="index" @click="To(item)" :class="index % 2 == 0?'bg1':'bg2'">
          <div style="width: 100%;display: flex;">
            <div class="m_1">
              {{index + 1}}
            </div>
            <div class="m_2" @click="xian(item)">
              <div style="margin-left: 1rem;">
               {{item.projectName}}
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

    <!-- 翻页 -->
    <div style="width: 100%; height: 5rem; padding: 1rem 0; margin-top: 4rem;">
      <!-- <span class="demonstration">完整功能</span> -->
      <el-pagination style="float: right;" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage4"
        :page-sizes="[10, 20, 30, 40]" :page-size="10" layout="total, sizes, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'gsgly',
    data() {
      return {
        list: [
          {
            name:'在线检测',
            qr:false
          }
          ,
           {
             name:'项目管理',
             qr:false
           }
           ,{
             name:'设备管理',
             qr:false
           }, {
             name:'施工管理',
             qr:false
           },{
             name:'告警管理',
             qr:false
           },{
             name:'账号管理',
             qr:false
           }

        ],
        value: true,
        currentPage4: 1,
        obj: {
          "page": 1,
          "size": 10
        },
        Data:[],
        total:null
      }
    },
    created() {
      this.info()
    },
    methods: {
      To(item){
        this.$router.push('/gjsz_1?id='+item.id + '&projectName=' + item.projectName)
      },
      handleSizeChange(val) {
        console.log(`每页 ${val} 条`);
        this.obj.size = val
        this.info()

      },
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`);
        this.obj.page = val
        this.info()
      },
      xian(item){
        item.qr = !item.qr
      },
      info() {
        this.$ajax('xmcx', 'post', this.obj).then(res => {
          console.log(res);
          this.Data = res.data.records
          this.total = res.data.total

        })
      }
    },
  }
</script>

<style lang="scss" scoped>
  .bg {
    background-color: rgba(43, 201, 222, 0.6);
  }

  .bg1 {
    background-color: rgba(162, 162, 162, 0.1);
  }

  .bg2 {
    background: rgba(14, 242, 242, 0.25);
  }
  .m1 {
    position: relative;
    color: #FFFFFF;
    margin: 1rem 0 0 .3rem;
    width: 156.6rem;

    // height: 59.4rem;

    .hd {
      position: relative;
      width: 100%;
      height: 5rem;


      .h1 {
        display: inline-block;
        width: 11.5rem;
        height: 5rem;
        line-height: 5rem;
        padding-left: 1rem;
        font-size: 1.8rem;
      }

      .h2 {
        position: absolute;
        top: 1rem;
        right: 2rem;
        width: 11.5rem;
        height: 4.2rem;
        // background-color: rgba(43, 201, 222, 1);
        line-height: 4.2rem;
        text-align: center;
        border-radius: .5rem;
      }
    }

    .lb {
      position: relative;
      // border: 1px solid #2BDEC9;
      margin-top: 1rem;
      font-size: 1.4rem;
      // overflow: auto;

      .hd {
        width: 100%;
        height: 3.6rem;
        line-height: 3.6rem;
        // padding-left: 1rem;
        font-size: 1.4rem;

        // border-bottom: 1px solid #2BDEC9;
        .hd_1 {
          display: inline-block;
          width: 5%;
          height: 4.2rem;
          line-height: 4.2rem;
          text-align: center;
          margin-right: .5%;
          // background-color: rgba(43, 201, 222, 0.6);
        }

        .hd_2 {
          display: inline-block;
          width: 94.5%;
          height: 4.2rem;
          line-height: 4.2rem;
          // background-color: rgba(43, 201, 222, 0.6);
        }

        .zh {
          margin-left: 1rem;
          display: inline-block;
          width: 16.8rem;
          overflow: hidden;
        }
      }

      .hd:last-child {
        // border-bottom: none;
      }

      .qx {
        position: absolute;
        top: .1rem;
        left: 50rem;
        display: inline-block;

        .lst {
          display: inline-block;
          width: 6.6rem;
          height: 2.6rem;
          line-height: 2.6rem;
          background-color: rgba(43, 201, 222, 0.6);
          text-align: center;
          border-radius: .3rem;
          margin-right: 1rem;
        }
      }

      .xg {
        position: absolute;
        top: .5rem;
        right: 2rem;
        width: 27rem;
        height: 2.6rem;
        line-height: 2.6rem;

        .gg {
          display: inline-block;
          width: 6.6rem;
          height: 2.6rem;
          line-height: 2.6rem;
          text-align: center;
          background-color: rgba(28, 184, 184, 1);
          border-radius: .4rem;
          margin-left: 1rem;
        }
      }
    }

    .main {
      position: relative;
      width: 100%;
      // height: 4.2rem;
      // margin-top: 1rem;
      padding: .5rem 0;

      .m_1 {
        display: inline-block;
        width: 5%;
        height: 4.2rem;
        line-height: 4.2rem;
        text-align: center;
        margin-right: .5%;
        // border: 1px solid rgba(43, 201, 222, 0.6);
        box-sizing: border-box;
      }

      .m_2 {
        display: inline-block;
        width: 94.5%;
        height: 4.2rem;
        line-height: 4.2rem;
        // border: 1px solid rgba(43, 201, 222, 0.6);
        box-sizing: border-box;
      }

      .m_3 {
        position: relative;
        box-sizing: border-box;
        float: right;
        width: 94.5%;
        height: 4.2rem;
        border: 1px solid rgba(43, 201, 222, 0.6);
        box-sizing: border-box;
        margin-top: .5rem;
        line-height: 4.2rem;
        .zh {
          margin-left: 1rem;
          display: inline-block;
          width: 16.8rem;
          overflow: hidden;
        }

        .qx {
          position: absolute;
          top: .1rem;
          left: 50rem;
          display: inline-block;

          .lst {
            display: inline-block;
            width: 6.6rem;
            height: 2.6rem;
            line-height: 2.6rem;
            background-color: rgba(43, 201, 222, 0.6);
            text-align: center;
            border-radius: .3rem;
            margin-right: 1rem;
          }
        }

        .xg {
          position: absolute;
          top: .5rem;
          right: 2rem;
          width: 27rem;
          height: 2.6rem;
          line-height: 2.6rem;

          .gg {
            display: inline-block;
            width: 6.6rem;
            height: 2.6rem;
            line-height: 2.6rem;
            text-align: center;
            background-color: rgba(28, 184, 184, 1);
            border-radius: .4rem;
            margin-left: 1rem;
          }
        }
      }
      .m_3:last-child{
        margin-bottom: 1rem;
      }
    }
    .main:hover {
      background-color: rgba(117, 117, 117, 0.4);
    }
  }
</style>
